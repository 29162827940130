<template>
  <v-container fluid class="px-6" >

    <v-card v-show="this.$store.getters.user.idUserAccess == 1">
      <v-card-title class="px-6 card-header white--text">
        <v-icon class="mr-3 white--text">mdi-format-list-bulleted</v-icon> <h3>User List</h3>
      </v-card-title>

      <div style="max-height: calc(100vh - 190px);overflow-y:auto;">
        <v-data-table
        :headers="headers"
        :items="itemsWithIndex"
        :loading="loadingTable"
        class="elevation-2 headerDtSarawak px-6 pt-6"
        >
          <template v-slot:[`item.no`]="props">
            {{ props.item.index }}
          </template>

          <template v-slot:[`item.fullname`]="props">
            {{ props.item.firstname }} {{ props.item.lastname }}
          </template>

          <template v-slot:[`item.actions`]="props">
            <template>
              <v-icon
                small
                class="mr-2 green--text"
                @click="editUserItem(props.item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                text
                class="red--text"
                @click="deleteUser(props.item.idUser,props.item.name,props.item)"
                >mdi-delete</v-icon
              >
            </template>

          </template>
        </v-data-table>
      </div>
    </v-card>

    <!------ Dialog Edit User ------>
    <v-dialog v-model="editDialog" max-width="700px" persistent>
      <v-card>
        <v-card-title class="card-header white--text">
          <v-icon class="white--text mr-2">mdi-account-edit</v-icon><h3>Edit User</h3>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedUser.firstname"
                  label="First Name"
                  dense outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedUser.lastname"
                  label="Last Name"
                  dense outlined 
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedUser.email"
                  label="Email"
                  dense outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  :items="listState"
                  v-model="selectedState"
                  label="State"
                  dense outlined
                  multiple
                  chips
                >
                
                  <template v-slot:prepend-item>
                  <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleSelectStates"
                  >
                    <v-list-item-action>
                          <v-icon :color="selectedState.length > 0 ? 'primary' : ''">
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Select All
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedUser.designation"
                  label="Designation"
                  dense outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedUser.idUserAccess"
                  :items="roles"
                  label="Role(s)"
                  dense outlined
                  item-value="idUserAccess"
                  item-text="userAccessName"
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" class="pb-0 pt-0">

                <v-checkbox
                  class="mr-4"
                  v-model="editedUser.alert1"
                  label="Alert"
                  false-value="0"
                  true-value="1"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="card-footer">
          <v-spacer></v-spacer>

          <!--<v-btn class="primary--text" text @click="close">
            Cancel
          </v-btn>

          <v-btn class="primary--text" text @click="postEditUser">
            Save
          </v-btn>-->

          <v-btn
            @click="editDialog=false"
            color="error"
          >
            CANCEL
          </v-btn>
          <v-btn
            @click="postEditUser"
            color="button" class="white--text px-4 mx-3" :loading="loadingButton"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!------ Dialog Delete User ------>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="error white--text">
          <v-icon class="white--text mr-2">mdi-delete-empty</v-icon><h3>Delete User : {{delUserName}}</h3>
        </v-card-title>
        <v-card-text style="padding:20px 24px;font-weight:bold;">
          Are you sure you want to delete this user?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close">Cancel</v-btn>
          <v-btn color="error" @click="postDeleteUser">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-container>
</template>


<script>
import axios from "axios";
import appMixins from '@/mixins/globalMixins'

export default {

  data: () => ({

    idUserSelected: null,
    loadingTable: false,

    loadingButton: false,
    editDialog: false,
    dialogDelete: false,
    delSelected: "",
    delUserName: "",
    headers: [
      { text: "NO.", align: "start", value: "no" },
      { text: "NAME", value: "fullname" },
      { text: "EMAIL", value: "email" },
      { text: "DESIGNATION", value: "designation" },
      { text: "ROLE(s)", value: "userAccessFname" },
      { text: "ACTIONS", value: "actions", sortable: false },
    ],

    userData: [],
    roles: [],
    listState: [],
    selectedState: [],
    editedUser: {
      firstname: "",
      lastname: "",
      email: "",
      designation: "",
      phone: "",
      roles: "",
      alert1: "",
      dataValidationStatus: "",
      idUserAccess: ""
    },
    editedIndex: -1,
  }),

  mounted() {
    this.getAllUser();
    this.getDropdowns();
  },

  methods: {
    getAllUser() {

      this.loadingTable = true;
      this.userData = [];

      axios
        .get(this.globalUrl+`mqm2/users/all`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.userData = response.data;

          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

    getDropdowns(){
      this.roles = [];
      axios
      .get(this.globalUrl+`mqm2/users/alldropdowns`, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;

        console.log(data);

        this.roles = data.userAccess;
        this.listState = data.states;
      })
      .catch((error) => {
        console.log(error);
      });
    },

    toggleSelectStates(){

      this.$nextTick(() => {
        if (this.selectAllStates) {
          this.selectedState = []
        } else {
          this.selectedState = this.listState.slice()
        }
      })

    },

    postEditUser() {
      this.loadingButton = true;
      const data = this.editedUser;

      let payload = {
        IdUser: data.iduser,
        idUserAccess: data.idUserAccess,
        dataValidationStatus: data.dataValidationStatus,
        name: data.name,
        phoneNo: data.phoneNo,
        firstname: data.firstname,
        lastname: data.lastname,
        role: data.role,
        designation: data.designation,
        alert1: data.alert1,
        state: this.selectedState.join(',')
      }

      console.log(payload);

      console.log(JSON.stringify(payload));
      console.log(this.globalUrl+'mqm2/users/edit');
      
      axios
        .post(this.globalUrl+'mqm2/users/edit', payload, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.getAllUser();
          this.loadingButton = false;
          this.close();
        })
        .catch((error) => {
          this.loadingButton = false;
          console.log(error);
        });
    },

    postDeleteUser() {
      axios
        .get(this.globalUrl+`mqm2/users/del?id=`+this.idUserSelected,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          // let temp = this.userData.findIndex(
          //   (item) => item.idUser === this.delSelected
          // );
          // this.userData.splice(temp, 1);
          // this.close();

          this.getAllUser();
          this.dialogDelete = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editUserItem(item) {

      console.log(item);
      this.selectedState = [];

      if(item.state != "" && item.state != null){
        this.selectedState = item.state.split(",");
      }

      console.log(this.selectedState);
      

      this.editedIndex = this.userData.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.editDialog = true;
    },

    deleteUser(val,user,data) {

      // console.log(data);
      this.idUserSelected = data.iduser
      this.delSelected = val;
      this.delUserName = user;
      this.dialogDelete = true;
    },

    close() {
      this.editDialog = false;
      this.dialogDelete = false;
    },
  },

  computed: {
    itemsWithIndex() {
      return this.userData.map((userData, index) => ({
        ...userData,
        index: index + 1,
      }));
    },
    selectAllStates () {
      return this.selectedState.length === this.listState.length
    },
    selectSomeStates () {
      return this.selectedState.length > 0 && !this.selectAllStates
    },
    icon () {
      if (this.selectAllStates) return 'mdi-close-box'
      if (this.selectSomeStates) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

};
</script>

<style lang="scss" scoped>
@import "~scss/main";



    
</style>